/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateClientRequest interface.
 */
export function instanceOfCreateClientRequest(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('location' in value) || value['location'] === undefined)
        return false;
    if (!('employeeCount' in value) || value['employeeCount'] === undefined)
        return false;
    if (!('billingCycle' in value) || value['billingCycle'] === undefined)
        return false;
    return true;
}
export function CreateClientRequestFromJSON(json) {
    return CreateClientRequestFromJSONTyped(json, false);
}
export function CreateClientRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'location': json['location'],
        'employeeCount': json['employeeCount'],
        'billingCycle': json['billingCycle'],
        'mode': json['mode'] == null ? undefined : json['mode'],
    };
}
export function CreateClientRequestToJSON(json) {
    return CreateClientRequestToJSONTyped(json, false);
}
export function CreateClientRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'location': value['location'],
        'employeeCount': value['employeeCount'],
        'billingCycle': value['billingCycle'],
        'mode': value['mode'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateClientAsAdminRequest interface.
 */
export function instanceOfCreateClientAsAdminRequest(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('location' in value) || value['location'] === undefined)
        return false;
    if (!('employeeCount' in value) || value['employeeCount'] === undefined)
        return false;
    return true;
}
export function CreateClientAsAdminRequestFromJSON(json) {
    return CreateClientAsAdminRequestFromJSONTyped(json, false);
}
export function CreateClientAsAdminRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'location': json['location'],
        'employeeCount': json['employeeCount'],
        'mode': json['mode'] == null ? undefined : json['mode'],
        'serviceproviderUUID': json['serviceproviderUUID'] == null ? undefined : json['serviceproviderUUID'],
    };
}
export function CreateClientAsAdminRequestToJSON(json) {
    return CreateClientAsAdminRequestToJSONTyped(json, false);
}
export function CreateClientAsAdminRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'location': value['location'],
        'employeeCount': value['employeeCount'],
        'mode': value['mode'],
        'serviceproviderUUID': value['serviceproviderUUID'],
    };
}

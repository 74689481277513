/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetDatabaseChangeRequestEventFromJSON, SetDatabaseChangeRequestEventToJSON, } from './SetDatabaseChangeRequestEvent';
import { SetDatabaseChangeRequestDeliveryInfoFromJSON, SetDatabaseChangeRequestDeliveryInfoToJSON, } from './SetDatabaseChangeRequestDeliveryInfo';
import { SetDatabaseChangeRequestTableFromJSON, SetDatabaseChangeRequestTableToJSON, } from './SetDatabaseChangeRequestTable';
import { SetDatabaseChangeRequestTriggerFromJSON, SetDatabaseChangeRequestTriggerToJSON, } from './SetDatabaseChangeRequestTrigger';
/**
 * Check if a given object implements the SetDatabaseChangeRequest interface.
 */
export function instanceOfSetDatabaseChangeRequest(value) {
    return true;
}
export function SetDatabaseChangeRequestFromJSON(json) {
    return SetDatabaseChangeRequestFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'createdAt': json['created_at'] == null ? undefined : json['created_at'],
        'deliveryInfo': json['delivery_info'] == null ? undefined : SetDatabaseChangeRequestDeliveryInfoFromJSON(json['delivery_info']),
        'event': json['event'] == null ? undefined : SetDatabaseChangeRequestEventFromJSON(json['event']),
        'id': json['id'] == null ? undefined : json['id'],
        'table': json['table'] == null ? undefined : SetDatabaseChangeRequestTableFromJSON(json['table']),
        'trigger': json['trigger'] == null ? undefined : SetDatabaseChangeRequestTriggerFromJSON(json['trigger']),
    };
}
export function SetDatabaseChangeRequestToJSON(json) {
    return SetDatabaseChangeRequestToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['createdAt'],
        'delivery_info': SetDatabaseChangeRequestDeliveryInfoToJSON(value['deliveryInfo']),
        'event': SetDatabaseChangeRequestEventToJSON(value['event']),
        'id': value['id'],
        'table': SetDatabaseChangeRequestTableToJSON(value['table']),
        'trigger': SetDatabaseChangeRequestTriggerToJSON(value['trigger']),
    };
}

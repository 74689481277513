/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetDatabaseChangeRequestEventDataNewFromJSON, SetDatabaseChangeRequestEventDataNewToJSON, } from './SetDatabaseChangeRequestEventDataNew';
/**
 * Check if a given object implements the SetDatabaseChangeRequestEventData interface.
 */
export function instanceOfSetDatabaseChangeRequestEventData(value) {
    return true;
}
export function SetDatabaseChangeRequestEventDataFromJSON(json) {
    return SetDatabaseChangeRequestEventDataFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        '_new': json['new'] == null ? undefined : SetDatabaseChangeRequestEventDataNewFromJSON(json['new']),
    };
}
export function SetDatabaseChangeRequestEventDataToJSON(json) {
    return SetDatabaseChangeRequestEventDataToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'new': SetDatabaseChangeRequestEventDataNewToJSON(value['_new']),
    };
}

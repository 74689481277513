/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const GetCollectorHealthStatus200ResponseStatusEnum = {
    Ok: 'ok',
    Restart: 'restart'
};
/**
 * Check if a given object implements the GetCollectorHealthStatus200Response interface.
 */
export function instanceOfGetCollectorHealthStatus200Response(value) {
    return true;
}
export function GetCollectorHealthStatus200ResponseFromJSON(json) {
    return GetCollectorHealthStatus200ResponseFromJSONTyped(json, false);
}
export function GetCollectorHealthStatus200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'status': json['status'] == null ? undefined : json['status'],
    };
}
export function GetCollectorHealthStatus200ResponseToJSON(json) {
    return GetCollectorHealthStatus200ResponseToJSONTyped(json, false);
}
export function GetCollectorHealthStatus200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'status': value['status'],
    };
}

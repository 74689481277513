/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetServiceprovider200ResponseWhitelabel interface.
 */
export function instanceOfGetServiceprovider200ResponseWhitelabel(value) {
    if (!('isActive' in value) || value['isActive'] === undefined)
        return false;
    return true;
}
export function GetServiceprovider200ResponseWhitelabelFromJSON(json) {
    return GetServiceprovider200ResponseWhitelabelFromJSONTyped(json, false);
}
export function GetServiceprovider200ResponseWhitelabelFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'hasLogo': json['hasLogo'] == null ? undefined : json['hasLogo'],
        'isActive': json['isActive'],
    };
}
export function GetServiceprovider200ResponseWhitelabelToJSON(json) {
    return GetServiceprovider200ResponseWhitelabelToJSONTyped(json, false);
}
export function GetServiceprovider200ResponseWhitelabelToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'phone': value['phone'],
        'hasLogo': value['hasLogo'],
        'isActive': value['isActive'],
    };
}

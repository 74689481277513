/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SetContactDetailsRequest interface.
 */
export function instanceOfSetContactDetailsRequest(value) {
    return true;
}
export function SetContactDetailsRequestFromJSON(json) {
    return SetContactDetailsRequestFromJSONTyped(json, false);
}
export function SetContactDetailsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'] == null ? undefined : json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'phone': json['phone'] == null ? undefined : json['phone'],
    };
}
export function SetContactDetailsRequestToJSON(json) {
    return SetContactDetailsRequestToJSONTyped(json, false);
}
export function SetContactDetailsRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'name': value['name'],
        'phone': value['phone'],
    };
}

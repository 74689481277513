/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddServiceproviderTeamMemberRequest interface.
 */
export function instanceOfAddServiceproviderTeamMemberRequest(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('firstname' in value) || value['firstname'] === undefined)
        return false;
    if (!('lastname' in value) || value['lastname'] === undefined)
        return false;
    return true;
}
export function AddServiceproviderTeamMemberRequestFromJSON(json) {
    return AddServiceproviderTeamMemberRequestFromJSONTyped(json, false);
}
export function AddServiceproviderTeamMemberRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'firstname': json['firstname'],
        'lastname': json['lastname'],
    };
}
export function AddServiceproviderTeamMemberRequestToJSON(json) {
    return AddServiceproviderTeamMemberRequestToJSONTyped(json, false);
}
export function AddServiceproviderTeamMemberRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
    };
}

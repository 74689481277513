/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetServiceprovider200ResponseQuickcheck interface.
 */
export function instanceOfGetServiceprovider200ResponseQuickcheck(value) {
    return true;
}
export function GetServiceprovider200ResponseQuickcheckFromJSON(json) {
    return GetServiceprovider200ResponseQuickcheckFromJSONTyped(json, false);
}
export function GetServiceprovider200ResponseQuickcheckFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'origin': json['origin'] == null ? undefined : json['origin'],
        'privacy': json['privacy'] == null ? undefined : json['privacy'],
    };
}
export function GetServiceprovider200ResponseQuickcheckToJSON(json) {
    return GetServiceprovider200ResponseQuickcheckToJSONTyped(json, false);
}
export function GetServiceprovider200ResponseQuickcheckToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'origin': value['origin'],
        'privacy': value['privacy'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ActivateCollectorLicense201ResponseFromJSON, ActivateCollectorLicenseRequestToJSON, AddClientTeamMember200ResponseInnerFromJSON, AddClientTeamMemberRequestToJSON, CheckUpdate200ResponseFromJSON, CreateClientAsAdmin200ResponseFromJSON, CreateClientRequestToJSON, CreateNewQuickcheckClientRequestToJSON, GetAllIoes200ResponseFromJSON, GetClientLicense200ResponseFromJSON, GetClientTeam200ResponseInnerFromJSON, GetClientTeammember200ResponseFromJSON, GetCollectorHealthStatus200ResponseFromJSON, GetCollectorLicense200ResponseFromJSON, GetContactDetails200ResponseFromJSON, GetEntraMeta200ResponseFromJSON, GetEntraMeta200ResponseToJSON, GetInstallerLicense200ResponseFromJSON, InitialScanRequestFromJSON, InitialScanRequestToJSON, RemoveClientTeamMemberRequestToJSON, SetContactDetailsRequestToJSON, SetEmployeeCountRequestToJSON, UpdateIoeRequestToJSON, } from '../models/index';
/**
 *
 */
export class ClientApi extends runtime.BaseAPI {
    /**
     * Get client license with data for collector
     */
    activateCollectorLicenseRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling activateCollectorLicense().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/license/activate`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ActivateCollectorLicenseRequestToJSON(requestParameters['activateCollectorLicenseRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivateCollectorLicense201ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get client license with data for collector
     */
    activateCollectorLicense(clientUUID, activateCollectorLicenseRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activateCollectorLicenseRaw({ clientUUID: clientUUID, activateCollectorLicenseRequest: activateCollectorLicenseRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get add new member to client team
     */
    addClientTeamMemberRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling addClientTeamMember().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/collaboration/team`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AddClientTeamMemberRequestToJSON(requestParameters['addClientTeamMemberRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddClientTeamMember200ResponseInnerFromJSON));
        });
    }
    /**
     * Get add new member to client team
     */
    addClientTeamMember(clientUUID, addClientTeamMemberRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.addClientTeamMemberRaw({ clientUUID: clientUUID, addClientTeamMemberRequest: addClientTeamMemberRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Trigger security calculation for client
     */
    calculateSecurityScoreRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling calculateSecurityScore().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/ioe/security-score`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Trigger security calculation for client
     */
    calculateSecurityScore(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.calculateSecurityScoreRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Cancel subscription for client
     */
    cancelSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling cancelSubscription().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/license/cancel`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Cancel subscription for client
     */
    cancelSubscription(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cancelSubscriptionRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Check if there is an update available for the collector
     */
    checkUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling checkUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/update`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CheckUpdate200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Check if there is an update available for the collector
     */
    checkUpdate(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkUpdateRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new client
     */
    createClientRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createClientRequest'] == null) {
                throw new runtime.RequiredError('createClientRequest', 'Required parameter "createClientRequest" was null or undefined when calling createClient().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/new`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateClientRequestToJSON(requestParameters['createClientRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create a new client
     */
    createClient(createClientRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createClientRaw({ createClientRequest: createClientRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create new quickcheck client
     */
    createNewQuickcheckClientRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/quickcheck/new`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateNewQuickcheckClientRequestToJSON(requestParameters['createNewQuickcheckClientRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create new quickcheck client
     */
    createNewQuickcheckClient(createNewQuickcheckClientRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createNewQuickcheckClientRaw({ createNewQuickcheckClientRequest: createNewQuickcheckClientRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete client
     */
    deleteClientRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling deleteClient().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Delete client
     */
    deleteClient(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteClientRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all IOEs for client
     */
    getAllIoesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getAllIoes().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/ioe/states`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetAllIoes200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get all IOEs for client
     */
    getAllIoes(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllIoesRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get client license
     */
    getClientLicenseRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getClientLicense().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/license`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetClientLicense200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get client license
     */
    getClientLicense(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getClientLicenseRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get client report pdf
     */
    getClientReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getClientReport().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/report`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Get client report pdf
     */
    getClientReport(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getClientReportRaw({ clientUUID: clientUUID }, initOverrides);
        });
    }
    /**
     * Get client team members
     */
    getClientTeamRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getClientTeam().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collaboration/team`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientTeam200ResponseInnerFromJSON));
        });
    }
    /**
     * Get client team members
     */
    getClientTeam(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getClientTeamRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get team member
     */
    getClientTeammemberRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getClientTeammember().');
            }
            if (requestParameters['userUUID'] == null) {
                throw new runtime.RequiredError('userUUID', 'Required parameter "userUUID" was null or undefined when calling getClientTeammember().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collaboration/team/member/{userUUID}`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))).replace(`{${"userUUID"}}`, encodeURIComponent(String(requestParameters['userUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetClientTeammember200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get team member
     */
    getClientTeammember(clientUUID, userUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getClientTeammemberRaw({ clientUUID: clientUUID, userUUID: userUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get current audit status as string - displayed during scan
     */
    getCollectorAuditStatusRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getCollectorAuditStatus().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/audit/status`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => InitialScanRequestFromJSON(jsonValue));
        });
    }
    /**
     * Get current audit status as string - displayed during scan
     */
    getCollectorAuditStatus(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCollectorAuditStatusRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get collector config as prep for selfhosted instances
     */
    getCollectorConfigRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getCollectorConfig().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/config`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Get collector config as prep for selfhosted instances
     */
    getCollectorConfig(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCollectorConfigRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get collector health status - Determines if collector should be restarted by scheduled task
     */
    getCollectorHealthStatusRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getCollectorHealthStatus().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/health`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCollectorHealthStatus200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get collector health status - Determines if collector should be restarted by scheduled task
     */
    getCollectorHealthStatus(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCollectorHealthStatusRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get client license with data for collector
     */
    getCollectorLicenseRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getCollectorLicense().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/license`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ActivateCollectorLicenseRequestToJSON(requestParameters['activateCollectorLicenseRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCollectorLicense200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get client license with data for collector
     */
    getCollectorLicense(clientUUID, activateCollectorLicenseRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCollectorLicenseRaw({ clientUUID: clientUUID, activateCollectorLicenseRequest: activateCollectorLicenseRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get client contact details
     */
    getContactDetailsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getContactDetails().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/contact`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetContactDetails200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get client contact details
     */
    getContactDetails(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getContactDetailsRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Entra ID settings
     */
    getEntraMetaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getEntraMeta().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/entra/data`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetEntraMeta200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get Entra ID settings
     */
    getEntraMeta(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getEntraMetaRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get installer with config as file, used from dashboard
     */
    getInstallerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getInstaller().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/installer`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Get installer with config as file, used from dashboard
     */
    getInstaller(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getInstallerRaw({ clientUUID: clientUUID }, initOverrides);
        });
    }
    /**
     * Get client license with data for installer
     */
    getInstallerLicenseRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getInstallerLicense().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/installer/license`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetInstallerLicense200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get client license with data for installer
     */
    getInstallerLicense(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getInstallerLicenseRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get quickcheck installer with config as file, used from email
     */
    getQuickcheckInstallerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getQuickcheckInstaller().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/quickcheck/collector/installer`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Get quickcheck installer with config as file, used from email
     */
    getQuickcheckInstaller(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getQuickcheckInstallerRaw({ clientUUID: clientUUID }, initOverrides);
        });
    }
    /**
     * Check if there is an update available for the collector in dashboard
     */
    getVersionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling getVersion().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/version`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CheckUpdate200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Check if there is an update available for the collector in dashboard
     */
    getVersion(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getVersionRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Report status of initial scan
     */
    initialScanRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling initialScan().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/initialscan`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InitialScanRequestToJSON(requestParameters['initialScanRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Report status of initial scan
     */
    initialScan(clientUUID, initialScanRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.initialScanRaw({ clientUUID: clientUUID, initialScanRequest: initialScanRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Remove client team member
     */
    removeClientTeamMemberRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling removeClientTeamMember().');
            }
            if (requestParameters['removeClientTeamMemberRequest'] == null) {
                throw new runtime.RequiredError('removeClientTeamMemberRequest', 'Required parameter "removeClientTeamMemberRequest" was null or undefined when calling removeClientTeamMember().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/collaboration/team`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: RemoveClientTeamMemberRequestToJSON(requestParameters['removeClientTeamMemberRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Remove client team member
     */
    removeClientTeamMember(clientUUID, removeClientTeamMemberRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.removeClientTeamMemberRaw({ clientUUID: clientUUID, removeClientTeamMemberRequest: removeClientTeamMemberRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset all IoAs thresholds for client
     */
    resetIoaThresholdsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling resetIoaThresholds().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/ioa/thresholds/reset`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Reset all IoAs thresholds for client
     */
    resetIoaThresholds(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetIoaThresholdsRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset all IOEs criticalities for client
     */
    resetIoeCriticalitiesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling resetIoeCriticalities().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/ioe/criticalities/reset`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Reset all IOEs criticalities for client
     */
    resetIoeCriticalities(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetIoeCriticalitiesRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset all IOEs thresholds for client
     */
    resetIoeThresholdsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling resetIoeThresholds().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/ioe/thresholds/reset`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Reset all IOEs thresholds for client
     */
    resetIoeThresholds(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetIoeThresholdsRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Save Entra ID settings
     */
    saveEntraMetaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling saveEntraMeta().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/entra/data`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetEntraMeta200ResponseToJSON(requestParameters['getEntraMeta200Response']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Save Entra ID settings
     */
    saveEntraMeta(clientUUID, getEntraMeta200Response, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveEntraMetaRaw({ clientUUID: clientUUID, getEntraMeta200Response: getEntraMeta200Response }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload log files to client (used for debugging) for collector
     */
    sendFileLogRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling sendFileLog().');
            }
            if (requestParameters['logName'] == null) {
                throw new runtime.RequiredError('logName', 'Required parameter "logName" was null or undefined when calling sendFileLog().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/collector/log/{logName}`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))).replace(`{${"logName"}}`, encodeURIComponent(String(requestParameters['logName']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Upload log files to client (used for debugging) for collector
     */
    sendFileLog(clientUUID, logName, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sendFileLogRaw({ clientUUID: clientUUID, logName: logName }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update client contact details
     */
    setContactDetailsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling setContactDetails().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/contact`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SetContactDetailsRequestToJSON(requestParameters['setContactDetailsRequest']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update client contact details
     */
    setContactDetails(clientUUID, setContactDetailsRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.setContactDetailsRaw({ clientUUID: clientUUID, setContactDetailsRequest: setContactDetailsRequest }, initOverrides);
        });
    }
    /**
     * Set employee count for client
     */
    setEmployeeCountRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling setEmployeeCount().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/employeeCount`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SetEmployeeCountRequestToJSON(requestParameters['setEmployeeCountRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Set employee count for client
     */
    setEmployeeCount(clientUUID, setEmployeeCountRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.setEmployeeCountRaw({ clientUUID: clientUUID, setEmployeeCountRequest: setEmployeeCountRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update IoE
     */
    updateIoeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling updateIoe().');
            }
            if (requestParameters['ioeId'] == null) {
                throw new runtime.RequiredError('ioeId', 'Required parameter "ioeId" was null or undefined when calling updateIoe().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/client/{clientUUID}/ioe/{ioeId}`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))).replace(`{${"ioeId"}}`, encodeURIComponent(String(requestParameters['ioeId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateIoeRequestToJSON(requestParameters['updateIoeRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Update IoE
     */
    updateIoe(clientUUID, ioeId, updateIoeRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateIoeRaw({ clientUUID: clientUUID, ioeId: ioeId, updateIoeRequest: updateIoeRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload entra cert
     */
    uploadCertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling uploadCert().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/entra/certificate`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Upload entra cert
     */
    uploadCert(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.uploadCertRaw({ clientUUID: clientUUID }, initOverrides);
        });
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetDatabaseChangeRequestEventDataFromJSON, SetDatabaseChangeRequestEventDataToJSON, } from './SetDatabaseChangeRequestEventData';
/**
 * Check if a given object implements the SetDatabaseChangeRequestEvent interface.
 */
export function instanceOfSetDatabaseChangeRequestEvent(value) {
    return true;
}
export function SetDatabaseChangeRequestEventFromJSON(json) {
    return SetDatabaseChangeRequestEventFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': json['data'] == null ? undefined : SetDatabaseChangeRequestEventDataFromJSON(json['data']),
        'op': json['op'] == null ? undefined : json['op'],
    };
}
export function SetDatabaseChangeRequestEventToJSON(json) {
    return SetDatabaseChangeRequestEventToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'data': SetDatabaseChangeRequestEventDataToJSON(value['data']),
        'op': value['op'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetServiceprovider200ResponseWhitelabelFromJSON, GetServiceprovider200ResponseWhitelabelToJSON, } from './GetServiceprovider200ResponseWhitelabel';
import { GetServiceprovider200ResponseLicenseFromJSON, GetServiceprovider200ResponseLicenseToJSON, } from './GetServiceprovider200ResponseLicense';
import { GetServiceprovider200ResponseQuickcheckFromJSON, GetServiceprovider200ResponseQuickcheckToJSON, } from './GetServiceprovider200ResponseQuickcheck';
/**
 * Check if a given object implements the GetServiceprovider200Response interface.
 */
export function instanceOfGetServiceprovider200Response(value) {
    return true;
}
export function GetServiceprovider200ResponseFromJSON(json) {
    return GetServiceprovider200ResponseFromJSONTyped(json, false);
}
export function GetServiceprovider200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'serviceproviderId': json['serviceproviderId'] == null ? undefined : json['serviceproviderId'],
        'serviceproviderName': json['serviceproviderName'] == null ? undefined : json['serviceproviderName'],
        'whitelabel': json['whitelabel'] == null ? undefined : GetServiceprovider200ResponseWhitelabelFromJSON(json['whitelabel']),
        'quickcheck': json['quickcheck'] == null ? undefined : GetServiceprovider200ResponseQuickcheckFromJSON(json['quickcheck']),
        'clientCount': json['clientCount'] == null ? undefined : json['clientCount'],
        'license': json['license'] == null ? undefined : GetServiceprovider200ResponseLicenseFromJSON(json['license']),
    };
}
export function GetServiceprovider200ResponseToJSON(json) {
    return GetServiceprovider200ResponseToJSONTyped(json, false);
}
export function GetServiceprovider200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'serviceproviderId': value['serviceproviderId'],
        'serviceproviderName': value['serviceproviderName'],
        'whitelabel': GetServiceprovider200ResponseWhitelabelToJSON(value['whitelabel']),
        'quickcheck': GetServiceprovider200ResponseQuickcheckToJSON(value['quickcheck']),
        'clientCount': value['clientCount'],
        'license': GetServiceprovider200ResponseLicenseToJSON(value['license']),
    };
}

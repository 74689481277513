/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetUser200ResponseNotificationsFromJSON, GetUser200ResponseNotificationsToJSON, } from './GetUser200ResponseNotifications';
/**
 * Check if a given object implements the GetUser200Response interface.
 */
export function instanceOfGetUser200Response(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('notifications' in value) || value['notifications'] === undefined)
        return false;
    return true;
}
export function GetUser200ResponseFromJSON(json) {
    return GetUser200ResponseFromJSONTyped(json, false);
}
export function GetUser200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'notifications': GetUser200ResponseNotificationsFromJSON(json['notifications']),
    };
}
export function GetUser200ResponseToJSON(json) {
    return GetUser200ResponseToJSONTyped(json, false);
}
export function GetUser200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'notifications': GetUser200ResponseNotificationsToJSON(value['notifications']),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetClientTeammember200Response interface.
 */
export function instanceOfGetClientTeammember200Response(value) {
    return true;
}
export function GetClientTeammember200ResponseFromJSON(json) {
    return GetClientTeammember200ResponseFromJSONTyped(json, false);
}
export function GetClientTeammember200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}
export function GetClientTeammember200ResponseToJSON(json) {
    return GetClientTeammember200ResponseToJSONTyped(json, false);
}
export function GetClientTeammember200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
    };
}

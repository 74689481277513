/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetAllIoes200ResponseIoeInnerSettingFromJSON, GetAllIoes200ResponseIoeInnerSettingToJSON, } from './GetAllIoes200ResponseIoeInnerSetting';
/**
 * Check if a given object implements the GetAllIoes200ResponseIoeInner interface.
 */
export function instanceOfGetAllIoes200ResponseIoeInner(value) {
    return true;
}
export function GetAllIoes200ResponseIoeInnerFromJSON(json) {
    return GetAllIoes200ResponseIoeInnerFromJSONTyped(json, false);
}
export function GetAllIoes200ResponseIoeInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ioeIdPk': json['ioeIdPk'] == null ? undefined : json['ioeIdPk'],
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'criticality': json['criticality'] == null ? undefined : json['criticality'],
        'scoreImpact': json['scoreImpact'] == null ? undefined : json['scoreImpact'],
        'totalFindingsCount': json['totalFindingsCount'] == null ? undefined : json['totalFindingsCount'],
        'totalExceptionsCount': json['totalExceptionsCount'] == null ? undefined : json['totalExceptionsCount'],
        'allFindingsHaveException': json['allFindingsHaveException'] == null ? undefined : json['allFindingsHaveException'],
        'setting': json['setting'] == null ? undefined : GetAllIoes200ResponseIoeInnerSettingFromJSON(json['setting']),
        'isActive': json['isActive'] == null ? undefined : json['isActive'],
    };
}
export function GetAllIoes200ResponseIoeInnerToJSON(json) {
    return GetAllIoes200ResponseIoeInnerToJSONTyped(json, false);
}
export function GetAllIoes200ResponseIoeInnerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'ioeIdPk': value['ioeIdPk'],
        'title': value['title'],
        'description': value['description'],
        'criticality': value['criticality'],
        'scoreImpact': value['scoreImpact'],
        'totalFindingsCount': value['totalFindingsCount'],
        'totalExceptionsCount': value['totalExceptionsCount'],
        'allFindingsHaveException': value['allFindingsHaveException'],
        'setting': GetAllIoes200ResponseIoeInnerSettingToJSON(value['setting']),
        'isActive': value['isActive'],
    };
}

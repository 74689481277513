/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetServiceprovider200ResponseLicense interface.
 */
export function instanceOfGetServiceprovider200ResponseLicense(value) {
    return true;
}
export function GetServiceprovider200ResponseLicenseFromJSON(json) {
    return GetServiceprovider200ResponseLicenseFromJSONTyped(json, false);
}
export function GetServiceprovider200ResponseLicenseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'customerId': json['customerId'] == null ? undefined : json['customerId'],
        'subscriptionStatus': json['subscriptionStatus'] == null ? undefined : json['subscriptionStatus'],
        'freeSeats': json['freeSeats'] == null ? undefined : json['freeSeats'],
        'paymentDetailsRequired': json['paymentDetailsRequired'] == null ? undefined : json['paymentDetailsRequired'],
        'subscriptionItemId': json['subscriptionItemId'] == null ? undefined : json['subscriptionItemId'],
        'productName': json['productName'] == null ? undefined : json['productName'],
        'currentPeriodEnd': json['currentPeriodEnd'] == null ? undefined : json['currentPeriodEnd'],
    };
}
export function GetServiceprovider200ResponseLicenseToJSON(json) {
    return GetServiceprovider200ResponseLicenseToJSONTyped(json, false);
}
export function GetServiceprovider200ResponseLicenseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'customerId': value['customerId'],
        'subscriptionStatus': value['subscriptionStatus'],
        'freeSeats': value['freeSeats'],
        'paymentDetailsRequired': value['paymentDetailsRequired'],
        'subscriptionItemId': value['subscriptionItemId'],
        'productName': value['productName'],
        'currentPeriodEnd': value['currentPeriodEnd'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AddServiceproviderTeamMember201ResponseFromJSON, AddServiceproviderTeamMemberRequestToJSON, CreateClientAsAdmin200ResponseFromJSON, CreateServiceprovider201ResponseFromJSON, CreateServiceproviderRequestToJSON, GetClientTeam200ResponseInnerFromJSON, GetServiceprovider200ResponseFromJSON, RemoveClientTeamMemberRequestToJSON, SetQuickcheckSettingsRequestToJSON, UpdateServiceproviderRequestToJSON, } from '../models/index';
/**
 *
 */
export class ServiceproviderApi extends runtime.BaseAPI {
    /**
     * Add member to serviceprovider team
     */
    addServiceproviderTeamMemberRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling addServiceproviderTeamMember().');
            }
            if (requestParameters['addServiceproviderTeamMemberRequest'] == null) {
                throw new runtime.RequiredError('addServiceproviderTeamMemberRequest', 'Required parameter "addServiceproviderTeamMemberRequest" was null or undefined when calling addServiceproviderTeamMember().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/collaboration/team`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AddServiceproviderTeamMemberRequestToJSON(requestParameters['addServiceproviderTeamMemberRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AddServiceproviderTeamMember201ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Add member to serviceprovider team
     */
    addServiceproviderTeamMember(serviceproviderUUID, addServiceproviderTeamMemberRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.addServiceproviderTeamMemberRaw({ serviceproviderUUID: serviceproviderUUID, addServiceproviderTeamMemberRequest: addServiceproviderTeamMemberRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new serviceprovider
     */
    createServiceproviderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createServiceproviderRequest'] == null) {
                throw new runtime.RequiredError('createServiceproviderRequest', 'Required parameter "createServiceproviderRequest" was null or undefined when calling createServiceprovider().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/serviceprovider/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateServiceproviderRequestToJSON(requestParameters['createServiceproviderRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateServiceprovider201ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create a new serviceprovider
     */
    createServiceprovider(createServiceproviderRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createServiceproviderRaw({ createServiceproviderRequest: createServiceproviderRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete serviceprovider
     */
    deleteServiceproviderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling deleteServiceprovider().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Delete serviceprovider
     */
    deleteServiceprovider(serviceproviderUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteServiceproviderRaw({ serviceproviderUUID: serviceproviderUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get serviceprovider details
     */
    getServiceProviderTeamRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling getServiceProviderTeam().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/collaboration/team`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientTeam200ResponseInnerFromJSON));
        });
    }
    /**
     * Get serviceprovider details
     */
    getServiceProviderTeam(serviceproviderUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getServiceProviderTeamRaw({ serviceproviderUUID: serviceproviderUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get serviceprovider details
     */
    getServiceproviderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling getServiceprovider().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetServiceprovider200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get serviceprovider details
     */
    getServiceprovider(serviceproviderUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getServiceproviderRaw({ serviceproviderUUID: serviceproviderUUID }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Remover member from team
     */
    removeServiceproviderTeamMemberRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling removeServiceproviderTeamMember().');
            }
            if (requestParameters['removeClientTeamMemberRequest'] == null) {
                throw new runtime.RequiredError('removeClientTeamMemberRequest', 'Required parameter "removeClientTeamMemberRequest" was null or undefined when calling removeServiceproviderTeamMember().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/collaboration/team`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: RemoveClientTeamMemberRequestToJSON(requestParameters['removeClientTeamMemberRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Remover member from team
     */
    removeServiceproviderTeamMember(serviceproviderUUID, removeClientTeamMemberRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.removeServiceproviderTeamMemberRaw({ serviceproviderUUID: serviceproviderUUID, removeClientTeamMemberRequest: removeClientTeamMemberRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Set quickcheck settings
     */
    setQuickcheckSettingsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling setQuickcheckSettings().');
            }
            if (requestParameters['setQuickcheckSettingsRequest'] == null) {
                throw new runtime.RequiredError('setQuickcheckSettingsRequest', 'Required parameter "setQuickcheckSettingsRequest" was null or undefined when calling setQuickcheckSettings().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/quickcheck/settings`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SetQuickcheckSettingsRequestToJSON(requestParameters['setQuickcheckSettingsRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Set quickcheck settings
     */
    setQuickcheckSettings(serviceproviderUUID, setQuickcheckSettingsRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.setQuickcheckSettingsRaw({ serviceproviderUUID: serviceproviderUUID, setQuickcheckSettingsRequest: setQuickcheckSettingsRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update serviceprovider details
     */
    updateServiceproviderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling updateServiceprovider().');
            }
            if (requestParameters['updateServiceproviderRequest'] == null) {
                throw new runtime.RequiredError('updateServiceproviderRequest', 'Required parameter "updateServiceproviderRequest" was null or undefined when calling updateServiceprovider().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/whitelabel`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateServiceproviderRequestToJSON(requestParameters['updateServiceproviderRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Update serviceprovider details
     */
    updateServiceprovider(serviceproviderUUID, updateServiceproviderRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateServiceproviderRaw({ serviceproviderUUID: serviceproviderUUID, updateServiceproviderRequest: updateServiceproviderRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload whitelabel logo
     */
    uploadLogoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceproviderUUID'] == null) {
                throw new runtime.RequiredError('serviceproviderUUID', 'Required parameter "serviceproviderUUID" was null or undefined when calling uploadLogo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/serviceprovider/{serviceproviderUUID}/whitelabel/logo`.replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(requestParameters['serviceproviderUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Upload whitelabel logo
     */
    uploadLogo(serviceproviderUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.uploadLogoRaw({ serviceproviderUUID: serviceproviderUUID }, initOverrides);
        });
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SetDatabaseChangeRequestEventDataNew interface.
 */
export function instanceOfSetDatabaseChangeRequestEventDataNew(value) {
    return true;
}
export function SetDatabaseChangeRequestEventDataNewFromJSON(json) {
    return SetDatabaseChangeRequestEventDataNewFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventDataNewFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'repIdPk': json['rep_id_pk'] == null ? undefined : json['rep_id_pk'],
        'computerFk': json['computer_fk'] == null ? undefined : json['computer_fk'],
        'groupFk': json['group_fk'] == null ? undefined : json['group_fk'],
        'userFk': json['user_fk'] == null ? undefined : json['user_fk'],
        'isIoa': json['is_ioa'] == null ? undefined : json['is_ioa'],
        'isIoe': json['is_ioe'] == null ? undefined : json['is_ioe'],
        'newValue': json['new_value'] == null ? undefined : json['new_value'],
        'oldValue': json['old_value'] == null ? undefined : json['old_value'],
        'propertyName': json['property_name'] == null ? undefined : json['property_name'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'domainsObjectGuidFk': json['domains_object_guid_fk'] == null ? undefined : json['domains_object_guid_fk'],
        'clientUuid': json['client_uuid'] == null ? undefined : json['client_uuid'],
        'serviceproviderUuid': json['serviceprovider_uuid'] == null ? undefined : json['serviceprovider_uuid'],
    };
}
export function SetDatabaseChangeRequestEventDataNewToJSON(json) {
    return SetDatabaseChangeRequestEventDataNewToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestEventDataNewToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'rep_id_pk': value['repIdPk'],
        'computer_fk': value['computerFk'],
        'group_fk': value['groupFk'],
        'user_fk': value['userFk'],
        'is_ioa': value['isIoa'],
        'is_ioe': value['isIoe'],
        'new_value': value['newValue'],
        'old_value': value['oldValue'],
        'property_name': value['propertyName'],
        'timestamp': value['timestamp'],
        'domains_object_guid_fk': value['domainsObjectGuidFk'],
        'client_uuid': value['clientUuid'],
        'serviceprovider_uuid': value['serviceproviderUuid'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UpdateIoeRequest interface.
 */
export function instanceOfUpdateIoeRequest(value) {
    return true;
}
export function UpdateIoeRequestFromJSON(json) {
    return UpdateIoeRequestFromJSONTyped(json, false);
}
export function UpdateIoeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'disabledComment': json['disabled_comment'] == null ? undefined : json['disabled_comment'],
        'disabledReason': json['disabled_reason'] == null ? undefined : json['disabled_reason'],
        'disabledUserUid': json['disabled_user_uid'] == null ? undefined : json['disabled_user_uid'],
        'isDisabled': json['is_disabled'] == null ? undefined : json['is_disabled'],
    };
}
export function UpdateIoeRequestToJSON(json) {
    return UpdateIoeRequestToJSONTyped(json, false);
}
export function UpdateIoeRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'disabled_comment': value['disabledComment'],
        'disabled_reason': value['disabledReason'],
        'disabled_user_uid': value['disabledUserUid'],
        'is_disabled': value['isDisabled'],
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetContactDetails200Response interface.
 */
export function instanceOfGetContactDetails200Response(value) {
    return true;
}
export function GetContactDetails200ResponseFromJSON(json) {
    return GetContactDetails200ResponseFromJSONTyped(json, false);
}
export function GetContactDetails200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'clientAdminEmail': json['client_admin_email'] == null ? undefined : json['client_admin_email'],
        'clientEmergencyContactName': json['client_emergency_contact_name'] == null ? undefined : json['client_emergency_contact_name'],
        'clientEmergencyContactPhone': json['client_emergency_contact_phone'] == null ? undefined : json['client_emergency_contact_phone'],
    };
}
export function GetContactDetails200ResponseToJSON(json) {
    return GetContactDetails200ResponseToJSONTyped(json, false);
}
export function GetContactDetails200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'client_admin_email': value['clientAdminEmail'],
        'client_emergency_contact_name': value['clientEmergencyContactName'],
        'client_emergency_contact_phone': value['clientEmergencyContactPhone'],
    };
}
